export const getPaginationLimit = ({ url, postsPerPage }) => {
  // We deducted 1 slot on the first page, because we injected 1 native ad.
  let limit = postsPerPage - 1;
  let offset = 0;
  if (url.includes('page')) {
    limit = postsPerPage;

    const pageRegex = new RegExp(/\?page=(\d*)/);
    const pageMatch = pageRegex.exec(url);
    if (pageMatch && Array.isArray(pageMatch) && pageMatch[1]) {
      const parsedPage = parseInt(pageMatch[1], 10);
      offset = ((parsedPage - 1) * limit) + 1;
    }
  }

  return { limit, offset };
};
