/* eslint-disable react/prop-types */
import React from 'react';
import get from 'lodash.get';
import { HtmlSafe } from 'polaris-coreweb/exports';
import { getPrimaryMedia } from 'SharedPartialsLocal/getPrimaryMedia';
import { ReportCard } from '@autovia-uk/polaris-components/components/organisms/ReportCard';

import {
  getRelatedContent,
} from 'Helpers';

export const getReportCard = ({
  props,
  extraClassNames = {},
  layout = 'default',
}) => {
  const {
    layoutData: {
      page: {
        associatedContent,
        cons: rawCons,
        pageId,
        priceMax,
        priceMin,
        primaryMedia,
        product,
        pros: rawPros,
        rating,
        reviewType,
        specsPath,
        vehicleSpecList: {
          priceRange: {
            capPriceMax,
            capPriceMin,
          },
        },
      },
    },
  } = props;

  let priceOptions = {};
  let ratings = [];
  const reviewUrl = get(props, 'layoutData.page.ownerReview.url', null);
  const pageUrl = get(props, 'layoutData.page.url', null);

  const ownerReviewUrlsData = ['/audi/a1', '/fiat/500', '/ford/focus', '/mg/zs', '/bmw/2-series'];

  let ownerReviewCtaItem = {};

  const filteredOwnerReviewCtaItem = ownerReviewUrlsData.filter(item => item === pageUrl);

  if (filteredOwnerReviewCtaItem && filteredOwnerReviewCtaItem.length > 0) {
    ownerReviewCtaItem = {
      label: 'Submit your review',
      copy: 'Owner reviews with',
      image: {
        src: '/public/logo-carbuyer.svg',
      },
      ctaUrl: `https://www.carbuyer.co.uk${filteredOwnerReviewCtaItem[0]}/owner-reviews`,
    };
  }

  const relatedContent = getRelatedContent(associatedContent, 'relatedPages');

  const min = capPriceMin || priceMin;
  const max = capPriceMax || priceMax;

  if (min !== 0 || max !== 0) {
    priceOptions = {
      min,
      max,
      minOnly: false,
      prefixText: 'from ',
      priceSeparator: '-',
    };
  }

  let overAllRating = rating;
  const shouldHaveHowWeReviewLink = (reviewType && ['car', 'van'].includes(reviewType)) || false;

  let tabRating;
  const ratingsRounding = 1;
  if (relatedContent !== null && relatedContent.length > 0 && relatedContent[0].id !== pageId) {
    overAllRating = relatedContent[0].rating || 0;
    const currentSection = relatedContent.filter(item => item.id === pageId);
    if (currentSection && Array.isArray(currentSection) && currentSection.length) {
      tabRating = {
        showTextRating: true,
        showTextRatingWithTotal: false,
        value: rating,
        label: `${currentSection[0].title || 'This Section'} rating`,
        rounding: ratingsRounding,
        ctaLabel: shouldHaveHowWeReviewLink ? `How we review ${reviewType}s` : null,
        ctaUrl: shouldHaveHowWeReviewLink ? '/reviews/359539/trust-auto-express-reviews' : null,
      };
    }
  }

  let hasOverAllRating = false;
  if (overAllRating > 0) {
    hasOverAllRating = true;
    ratings = [
      {
        label: 'Overall Auto Express rating',
        value: overAllRating,
        showTextRating: true,
        showTextRatingWithTotal: false,
        rounding: ratingsRounding,
        ctaLabel: shouldHaveHowWeReviewLink ? `How we review ${reviewType}s` : null,
        ctaUrl: shouldHaveHowWeReviewLink ? '/reviews/359539/trust-auto-express-reviews' : null,
      },
    ];
  }

  if (tabRating) {
    ratings.pop(); // clear the ratings array so only the tab rating is shown
    ratings.push(tabRating);
  }

  const toList = (list) => {
    if (list.length > 0) {
      return `<ul>${list.map((item, index) => `<li key={report-list-item-${index}}>${item}</li>`).join('')}</ul>`;
    }

    return null;
  };

  const pros = Array.isArray(rawPros) ? toList(rawPros) : rawPros;
  const cons = Array.isArray(rawCons) ? toList(rawCons) : rawCons;
  const ctas = [];

  // ReportCard wont render if these are empty so neither will we
  if (!pros && !cons && ratings.length === 0 && Object.keys(priceOptions).length === 0) {
    return false;
  }

  if (reviewUrl !== null) {
    ctas.push({
      title: 'OWNER REVIEWS',
      url: reviewUrl,
    });
  }

  // Add SPEC link only if product exists - https://creativesolutions.atlassian.net/browse/APP-412
  // And add SPEC link from specsPath - https://creativesolutions.atlassian.net/browse/PAE-267
  if (product && specsPath) {
    ctas.push({
      title: 'SPECIFICATIONS',
      url: specsPath,
    });
  }

  /**
   * Checks if the report card has content to display.
   *
   * @returns {boolean} - Returns true if the report card has content, otherwise false.
   */
  const hasReportCardContent = () => (
    (ratings.length > 0 && ratings[0].value !== 0) // Check if there are ratings and the first rating value is not zero
      || Object.keys(priceOptions).length > 0 // Check if there are price options
      || pros // Check if there are pros
      || cons // Check if there are cons
      || ctas.length > 0 // Check if there are call-to-actions (CTAs)
      || Object.keys(ownerReviewCtaItem).length > 0 // Check if there is an owner review CTA item
  );

  // Returns false if the report card does not have content to display.
  if (!hasReportCardContent()) {
    return false;
  }

  const starConfig = {
    dimensions: '25px',
    spacings: '3px',
    // eslint-disable-next-line max-len
    path: 'M14.0502 2.74175L13.3334 1.21253L12.6165 2.74175L9.66346 9.04165H3.41113H1.5548L2.8398 10.3813L7.49701 15.2367L5.59926 22.7278L5.13961 24.5422L6.7617 23.6083L13.3334 19.8246L19.905 23.6083L21.5271 24.5422L21.0674 22.7278L19.1697 15.2367L23.8269 10.3813L25.1119 9.04165H23.2556H17.0033L14.0502 2.74175Z',
    viewBox: '0 0 26 26',
  };

  return (
    <ReportCard
      cons={cons && <HtmlSafe html={cons} />}
      consIcon=""
      consLabel=""
      ctas={ctas}
      extraClassNames={extraClassNames || undefined}
      extraComponent={getPrimaryMedia(primaryMedia, '-report-card')}
      layout={layout}
      ownerReviewCta={ownerReviewCtaItem}
      priceOptions={priceOptions}
      pros={pros && <HtmlSafe html={pros} />}
      prosIcon=""
      prosLabel=""
      ratings={ratings}
      ratingsRounding={ratingsRounding}
      starFill="#B08D03"
      starsDimensions={starConfig.dimensions}
      starsPath={starConfig.path}
      starsSpacings={starConfig.spacings}
      starsViewBox={starConfig.viewBox}
    />
  );
};
