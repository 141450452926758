import React from 'react';
import PropTypes from 'prop-types';

import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { getBreadcrumbs } from 'SharedPartialsLocal/getBreadcrumbs';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { getIndexPageItems } from 'SharedPartials/getIndexPageItems';
import { getLoadMore } from 'SharedPartials/getLoadMore';
import { getSponsorInfo } from 'SharedPartials/getSponsorInfo';
import { getTaxonomyFilter } from 'SharedPartialsLocal/getTaxonomyFilter';
import { getBody } from 'SharedPartialsLocal/getBody';

// Template styles and imports
import 'Styles/components/templates/_IndexTemplate.scss';

const IndexTemplate = ({
  layoutData: {
    page: {
      url,
      title,
      breadcrumbs,
      hideTitle,
      filters,
      showFilters,
      sponsor: sponsorInfo,
      body,
      layoutType,
    },
  },
  pagination: {
    isLoading,
    loadMore,
    setPageRef,
    pages,
    page: curPage,
    hasMore,
    nextUrl,
  },
  config: {
    adBreakPoints,
    globalSettings: {
      promoBoxSettings,
    },
  },
  isMobile,
}) => {
  const config = {
    promoBoxConfig: {
      ...promoBoxSettings,
      extraClassNames: {
        '-index': true,
      },
      imageSize: 'subscription',
      ppc: promoBoxSettings.subscriptionIndex,
    },
    adBreakPoints,
  };

  const taxonomyFilters = Array.isArray(filters) ? filters.map(f => ({
    label: f.title,
    value: f.url,
  })) : null;

  const taxonomyFilterParams = {
    label: 'Show me:',
    listModeOptions: {
      seeMoreLabel: 'See all',
      seeLessLabel: 'See less',
    },
    currentPageUrl: url,
    content: taxonomyFilters,
  };

  const loadMoreParams = {
    label: isLoading ? 'Loading...' : 'Load More',
    loadMore,
    isLoading,
    extraClassNames: {
      'polaris__load-more--small': true,
    },
    href: nextUrl,
  };

  const isEnhancedIndexPage = layoutType === 'enhanced_index_page';

  return (
    <div className={`polaris__body${isEnhancedIndexPage && ' -enhanced-index-page'}`}>
      <main className="polaris__main" id="main">
        {isEnhancedIndexPage && (
          <>
            <div className="polaris__core-content polaris__content">
              {getBody({
                bodyInjected: body,
                template: 'index-template',
              })}
            </div>
            <Ad
              extraClassNames={{
                '-full-width': true,
              }}
              id="refresh-inline_1"
              isNoBackground
              isSkippable
              isSpaceReserved
              targeting={{
                position: 'inline_1',
                placement: 'inline_1',
                refresh: 'yes',
              }}
              type="slot-1"
            />
          </>

        )}
        <div className="polaris__core-content polaris__content">
          {getBreadcrumbs(breadcrumbs)}

          <div className="polaris__index--headings-container">
            {!hideTitle && (<Heading size={1} extraClassNames={{ '-content-title': true }}>{title}</Heading>)}
            {getSponsorInfo({ sponsor: sponsorInfo })}
          </div>
        </div>

        {showFilters && taxonomyFilters !== null && taxonomyFilters.length > 0
        && (
          <div className="polaris__core-content polaris__content">
              {getTaxonomyFilter(taxonomyFilterParams)}
          </div>
        )}

        <div className="polaris__content">
          <div data-previous="1" ref={setPageRef} />
          {getIndexPageItems({
            adCountStart: {
              desktop: isEnhancedIndexPage ? 1 : 0,
              mobile: isEnhancedIndexPage ? 1 : 0,
            },
            articlesPerRow: 4,
            articleCardProps: {
              datePosition: 'bottom',
              kickerPosition: 'bottom',
              ratingPosition: 'over-image',
              showRating: true, // isn't enabled on live
            },
            config,
            extraClassNames: { '-news-index': true, 'polaris__core-content': true },
            newsletterPosition: false,
            nativeAdPosition: isEnhancedIndexPage ? 3 : 7,
            pages,
            promoboxPosition: 0, // No promobox
            setPageRef,
            templateType: 'indexTemplate',
            isMobile,
          })}
          <div className="polaris__core-content">
            {hasMore && getLoadMore(loadMoreParams)}
          </div>
        </div>
      </main>
    </div>
  );
};

/**
 * Valid props
 */
IndexTemplate.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.objectOf().isRequired,
  }).isRequired,
  pagination: PropTypes.shape({
    isLoading: PropTypes.bool,
    loadMore: PropTypes.func,
    page: PropTypes.number,
    pages: PropTypes.objectOf(),
    setPageRef: PropTypes.func,
    hasMore: PropTypes.bool,
    nextUrl: PropTypes.string,
  }).isRequired,
  config: PropTypes.shape({
    adBreakPoints: PropTypes.objectOf(),
    globalSettings: PropTypes.shape({
      promoBoxSettings: PropTypes.objectOf(PropTypes.string),
    }).isRequired,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default IndexTemplate;
