import React from 'react';
import PropTypes from 'prop-types';

import { getAuthorBio } from 'SharedPartialsLocal/getAuthorBio'; // AuthorBio: Image, Heading, Social
import { getBreadcrumbs } from 'SharedPartialsLocal/getBreadcrumbs';
import { getLoadMore } from 'SharedPartials/getLoadMore'; // Button and Link

import { getIndexPageItems } from 'SharedPartials/getIndexPageItems';

// Template styles and imports
import 'Styles/components/templates/_Author.scss';

const Author = ({
  layoutData: { page: { breadcrumbs, author } },
  pagination: {
    isLoading,
    loadMore,
    setPageRef,
    pages,
    hasMore,
  },
  config: {
    adBreakPoints,
    globalSettings: {
      promoBoxSettings,
    },
  },
}) => {
  const loadMoreParams = {
    label: isLoading ? 'Loading...' : 'Load More',
    loadMore,
    isLoading,
    extraClassNames: {
      'polaris__load-more--small': true,
    },
  };

  const config = {
    promoBoxConfig: {
      ...promoBoxSettings,
      imageSize: 'subscription',
      ppc: promoBoxSettings.subscriptionIndex,
    },
    adBreakPoints,
  };

  return (
    <div className="polaris__body">
      <main className="polaris__main -author-template" id="main">
        <div className="polaris__content polaris__core-content polaris__core-content-last">
          {getBreadcrumbs(breadcrumbs)}
        </div>

        <div className="polaris__gray--bg">
          <div className="polaris__content polaris__gray--bg polaris__core-content">
            {getAuthorBio(author)}
          </div>
        </div>

        <div className="polaris__content polaris__core-content">
          <div data-previous="1" ref={setPageRef} />
          {getIndexPageItems({
            articlesPerRow: 4,
            articleCardProps: {
              datePosition: 'bottom',
              kickerPosition: 'bottom',
            },
            config,
            newsletterPosition: false,
            nativeAdPosition: 7,
            pages,
            promoboxPosition: 7,
            setPageRef,
            templateType: 'indexTemplate',
          })}
          {hasMore && getLoadMore(loadMoreParams)}
        </div>
      </main>
    </div>
  );
};

Author.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.objectOf().isRequired,
  }).isRequired,
  pagination: PropTypes.shape({
    isLoading: PropTypes.bool,
    loadMore: PropTypes.func,
    pages: PropTypes.objectOf(),
    setPageRef: PropTypes.func,
    hasMore: PropTypes.bool,
  }).isRequired,
  config: PropTypes.shape().isRequired,
};


export { Author };
