import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { compose } from 'recompose';

import {
  withHelmet,
  withTemplate,
} from 'Protons';

import {
  getArticleGroupContent,
  getMultipageVehicleSpecs,
  getRightSideAdConfig,
  getSimilarProductContent,
} from 'Helpers';

import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { getNativeAd } from 'SharedPartials/getNativeAd';
import { getBreadcrumbs } from 'SharedPartialsLocal/getBreadcrumbs';
import { getGoTo } from 'SharedPartials/getGoTo';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { MultiPageNavigation } from '@autovia-uk/polaris-components/components/molecules/MultiPageNavigation';

import {
  getArticleGroup,
} from 'SharedPartialsLocal/getArticleGroup';

import SpecList from 'Templates/spec/SpecList';
import SpecOutro from 'Templates/spec/SpecOutro';
import VehicleSpecs from 'Templates/spec/VehicleSpecs';

// Template styles and imports
import 'Styles/components/templates/_Spec.scss';

/**
 * Get review page URL from spec page
 *
 * @param {*} url
 * @param {*} specTemplate
 */
export const getReviewURLFromSpecPage = (url, specTemplate) => {
  const pricesSpecsSlug = '/prices-specs';
  let reviewURLFromSpecPage = url.substring(0, url.indexOf(pricesSpecsSlug));
  if (specTemplate === 'specs') {
    reviewURLFromSpecPage += pricesSpecsSlug;
  }

  return reviewURLFromSpecPage;
};

/**
 * Get review page title from spec page
 *
 * @param {*} title
 * @param {*} specTemplate
 */
export const getReviewTitleFromSpecPage = (title, specTemplate) => {
  let reviewTitleFromSpecPage;
  if (specTemplate === 'specs') {
    reviewTitleFromSpecPage = 'All Models';
  } else {
    reviewTitleFromSpecPage = title.substring(0, title.indexOf(' : Prices & Specs'));

    // add "review" at end of title
    const titleSuffix = ' review';
    if (!reviewTitleFromSpecPage.endsWith(titleSuffix)) {
      reviewTitleFromSpecPage += titleSuffix;
    }
  }

  return reviewTitleFromSpecPage;
};

class SpecTemplate extends React.PureComponent {
  get template() {
    const { layoutData: { page: { contentType } } } = this.props;
    if (contentType === 'VEHICLE_SPECS') {
      return 'specs';
    }
    return 'spec-list';
  }

  renderSpec() {
    const {
      layoutData: {
        page: {
          tags,
          title,
          url,
          vehicleSpecs,
          vehicleSpecList,
        },
      },
      config: {
        siteUrl,
        social: {
          share: {
            platforms: socialSharingPlatforms,
          },
        },
      },
    } = this.props;

    const trims = get(vehicleSpecList, 'trims', []);

    return (
      <>
        <div className="polaris__core-content polaris__content polaris__simple-grid">
          <div className="polaris__simple-grid--aside">
            <Ad {...getRightSideAdConfig(1)} />
          </div>
          <div className="polaris__simple-grid-main">
            <div className="polaris__content--wrapper">
              <MultiPageNavigation
                selectedUrl={url}
                content={getMultipageVehicleSpecs(trims)}
                showCurrentlyReadingText
                showIndex={false}
              />
            </div>
          </div>

          <VehicleSpecs
            vehicleSpecs={vehicleSpecs}
          />

          <div className="polaris__simple-grid--aside">
            <Ad {...getRightSideAdConfig(2)} />
          </div>

          <div className="polaris__simple-grid-main">
            <SpecOutro url={url} title={title} tags={tags} socialSharingPlatforms={socialSharingPlatforms} siteUrl={siteUrl} />
          </div>
        </div>
      </>
    );
  }

  renderSpecList() {
    const {
      layoutData: {
        page: {
          title,
          url,
          tags,
        },
      },
      config: {
        siteUrl,
        social: {
          share: {
            platforms: socialSharingPlatforms,
          },
        },
      },
    } = this.props;
    const specList = get(this.props, 'layoutData.page.vehicleSpecList.trims', null);
    if (specList === null) {
      return false;
    }

    return (
      <>
        <SpecList vehicleSpecList={specList} />
        <SpecOutro url={url} title={title} tags={tags} socialSharingPlatforms={socialSharingPlatforms} siteUrl={siteUrl} />
      </>
    );
  }

  render() {
    const {
      layoutData: {
        page: {
          title,
          url,
          subtitle,
          kicker,
          isSponsored,
          associatedContent,
          breadcrumbs,
        },
      },
      config: {
        siteUrl,
      },
    } = this.props;

    return (
      <>
        <div className="polaris__body">
          <main className="polaris__main" id="main">
            <div className="polaris__core-content polaris__content">
              { getBreadcrumbs(breadcrumbs, isSponsored, kicker) }
              <Heading size={1} extraClassNames={{ '-content-title': true }}>
                { title }
              </Heading>
              {
                this.template === 'spec-list' && (
                  <Heading size={2} extraClassNames={{ '-content-subtitle': true }}>
                    { subtitle }
                  </Heading>
                )
              }
              {getGoTo(getReviewURLFromSpecPage(url, this.template), getReviewTitleFromSpecPage(title, this.template))}
            </div>

            {this.template === 'specs'
              ? this.renderSpec()
              : this.renderSpecList()}

            {/* Render Associated Contents */}
            <div className="polaris__core-content polaris__content">
              {getArticleGroup({
                title: 'Have you considered?',
                content: getSimilarProductContent(associatedContent),
                extraClassNames: {
                  'polaris__review--consider': true,
                  'polaris__core-content': true,
                },
                articleCardProps: {
                  datePosition: 'bottom',
                  excerpt: null,
                  kickerPosition: 'bottom',
                  ratingPosition: 'over-image',
                  showRating: true,
                },
              })}
              {getArticleGroup({
                title: 'Most Popular',
                content: getArticleGroupContent(associatedContent, 'mostPopular', {
                  component: [
                    <React.Fragment key="native_article">
                      {getNativeAd({
                        id: 'native_article',
                        targeting: {
                          position: 'native_article',
                          placement: 'native_article',
                          refresh: 'no',
                        },
                      })}
                    </React.Fragment>,
                  ],
                }),
                extraClassNames: {
                  'polaris__review--most-popular': true,
                  'polaris__polaris__spec--most-popular': true,
                },
                articleCardProps: {
                  datePosition: 'bottom',
                  isHeading: false,
                  kickerPosition: 'bottom',
                },
              })}
            </div>
          </main>
        </div>
      </>
    );
  }
}

SpecTemplate.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.objectOf().isRequired,
  }).isRequired,
  config: PropTypes.shape({
    siteUrl: PropTypes.string.isRequired,
    social: PropTypes.shape({
      share: PropTypes.shape({
        platforms: PropTypes.arrayOf(PropTypes.shape({
          label: PropTypes.string,
          platform: PropTypes.string,
        })),
      }),
    }),
  }).isRequired,
};

export default compose(
  withTemplate,
  withHelmet('spec'),
)(SpecTemplate);
