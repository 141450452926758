import React from 'react';
import uniqid from 'uniqid';
import {
  formatPageItemForWhitepaper,
} from 'Helpers';
import { getArticleGroup } from 'SharedPartialsLocal/getArticleGroup';

export const getSearchPageItems = (resultPages, setPageRef, keywords, blankMessage) => (
  Object.entries(resultPages).map(([page, items]) => {
    // Creates an array, mapping data to what the props expect.
    const content = items.map((item) => {
      let searchPageItem = {
        ...item,
        title: item.altTitle || item.title,
        type: item.sponsor ? 'sponsored' : 'default',
        image: {
          ...item.image,
          lazyLoad: true,
          isLCPCandidate: false,
        },
      };

      searchPageItem = formatPageItemForWhitepaper(searchPageItem, item);
      if (searchPageItem.resourceCategory) {
        delete searchPageItem.resourceCategory;
      }

      return searchPageItem;
    });

    // For the first search page item disable lazyloading and set isLCPCandidate to true
    if (content[0]) {
      content[0].image.lazyLoad = false;
      content[0].image.isLCPCandidate = true;
    }

    return (
      <div data-page={page} key={uniqid()} ref={setPageRef}>
        {getArticleGroup({
          title: '',
          content,
          articlesPerRow: 2,
          articleCardProps: {
            datePosition: 'bottom',
            kickerPosition: 'bottom',
            isHeading: true,
            ratingPosition: 'over-image',
            showRating: true,
          },
          blankMessage,
          extraClassNames: { '-search-index': true },
        })}
      </div>
    );
  }));
